<template>
    <div class="p-5 rounded-lg shadow relative min-h-[400px] bg-white dark:bg-gray-750">
        <template v-if="loading">
            <div class="absolute top-0 left-0 w-full h-full z-40 backdrop-blur-sm flex items-center justify-center">
                <Loading />
            </div>
        </template>

        <div class="flex items-center justify-between flex-wrap mb-3">
            <h2 class="font-semibold text-2xl dark:text-gray-300 mb-2">{{component.display_name}}</h2>

            <div class="flex items-center justify-center mb-2">
                <button
                    class="px-3 py-1 border border-gray-400 rounded mx-2 mb-3 text-gray-600 dark:bg-gray-700 dark:text-gray-300"
                    :class="[!thisYear ? 'bg-gray-300 dark:bg-gray-900' : '']"
                    @click="thisYear = false"
                >Šomēnes</button>

                <button
                    class="px-3 py-1 border border-gray-400 rounded mx-2 mb-3 text-gray-600 dark:bg-gray-700 dark:text-gray-300"
                    :class="[thisYear ? 'bg-gray-300 dark:bg-gray-900' : '']"
                    @click="thisYear = true"
                >Šogad</button>
            </div>
        </div>

        <template v-if="data && data.data">
            <!-- Tabel -->
            <div class="shadow overflow-hidden sm:rounded-lg" v-if="data && data.data && data.data.length > 0">
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                        <thead class="bg-gray-50 dark:bg-gray-800 dark:bg-opacity-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Nosaukums</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Kopējais pārdošanas apjoms</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Peļņa</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider">Pārdotās vienības</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 dark:divide-gray-600">
                            <template v-for="item in data.data" :key="item.id">
                                <tr class="">
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">{{item.name}}</td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">{{item.total_sales}}</td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">{{item.total_profit}}</td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-200">{{item.total_units}}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
            </div>

            <template v-if="data && data.data && data.data.length == 0">
                <div class="mx-auto">
                    <img class="max-h-64 mx-auto" src="/empty_cart.png">
                </div>
            </template>

            <!-- Pagination -->
            <template v-if="this.data && this.data.meta && this.data.meta.current_page && this.data.meta.last_page > 1">
                <div class="flex items-center justify-end sm:px-6 pt-3">
                    <div class="flex-1 flex items-center justify-end">
                        <div>
                            <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button @click="changPage(-1)" :disabled="this.data.meta.current_page == 1" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-750 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </button>

                                <button @click="changPage(1)" :disabled="this.data.meta.current_page == this.data.meta.last_page" type="button" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-750 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import axios from "axios"
var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

import { defineAsyncComponent } from 'vue'

const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))

export default {
    name: "CustomerSalesDataWidget",
    components: {
        Loading,
    },
    props: {
        component: {
            type: Object
        }
    },
    watch: {
        thisYear() {
            this.page = 1
            this.getData()
        }
    },
    data() {
        return {
            data: null,
            page: 1,
            loading: false,
            thisYear: false,
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            const thisYear = this.thisYear ? "&this_year=true" : ""
            axios
            .get(baseUrl + "/api/dashboard_components/" + this.component.id + "?page=" + this.page + thisYear)
            .then((response) => {
                this.data = response.data
                this.loading = false
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("destroyToken");
                }
                this.loading = false
            });
        },
        changPage(number) {
            this.page = this.page + number

            if (this.page < 1) {
                this.page = 1
            }
            if (this.page > this.data.meta.last_page) {
                this.page = this.data.meta.last_page
            }
            this.getData()
        },
        openOrderApplication(item){

            let routeData = this.$router.resolve(
                {
                path: '/production/production_applications/create/',
                query: {
                    name: item.stockable.name,
                    id: item.stockable.id,
                    quantity: item.is_for_production,
                }
                });
            window.open(routeData.href, '_blank');
        },
    }
}
</script>

<style>

</style>